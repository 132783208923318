import $ from 'jquery';
import _ from 'underscore';
import BaseView from 'picnic/core/views/Base';
import ButtonView from 'app/base/header/views/Button';
import ShareView from 'app/base/header/views/Share';


var
	SCROLL_OFFSET = 45,
	IS_MOBIE_CLASSNAME = 'is-mobile',
	NAVIGATION_CONTENT = '.subnavigation-menu'
;


class View extends BaseView {

	// Expanded is mobile menu which opens on click
	get mobileMenuIsExpanded() {
		return this.$el.hasClass('mobilemenu-is-open');
	}

	set mobileMenuIsExpanded(value) {
		this.$el.toggleClass('mobilemenu-is-open', value);
		this._navWrapper.attr('aria-expanded', value);
		this._navWrapper.attr('aria-hidden', !value);
		this._navWrapper.find('> ul > li > a').attr('tabindex', value ? 0 : -1);

		if (this._mobileMenuToggleButton) {
			this._mobileMenuToggleButton.isHidden = !this.isMobile;
		}
	}

	get subMenuIsOpen() {
		return this.$el.find('.is-open').length;
	}

	get isMobile() {
		return this.$el.find('.subnavigation-menu > ul').css('display') === 'block';
	}

	render() {
		super.render();

		this._shareIcon = new ShareView({
			el: this.$el.find('.share-section'),
			context: this.context
		}).render();

		this._navWrapper = this.$el.find(NAVIGATION_CONTENT);
		if (this._navWrapper.length) {
			this._mobileMenuToggleButton = new ButtonView({
				el: this.$el,
				context: this.context,
				controls: this._navWrapper.attr('id')
			}).render();
		}

		this._toggleAria(this.$el.find('.subnavigation'), false);
		this._toggleActiveMobileMenu(); // initially check if mobile or desktop

		if (this.isMobile) {
			this.mobileMenuIsExpanded = false; // set aria attributes
		}

		this._bindEvents();

		return this;
	}

	destroy() {
		this._unbindEvents();
		super.destroy();
	}

	_bindEvents() {
		_.bindAll(
			this,
			'_onCloseSubmenu',
			'_onToggleSubmenu',
			'_toggleActiveMobileMenu',
			'_onToggleMobileMenu',
			'_openSubmenu',
			'_bindEvents',
			'_onResize'
		);

		this.$el
			.on('click', '.toggle-subnav', this._onToggleSubmenu)
			.on('click', '.close-button button', this._onCloseSubmenu);

		if (this._mobileMenuToggleButton) {
			this._mobileMenuToggleButton.on('click', this._onToggleMobileMenu);
		}

		$(window)
			.on('resize', this._onResize)
			.on('scroll', this._onScroll);
	}

	_unbindEvents() {
		if (this._mobileMenuToggleButton) {
			this._mobileMenuToggleButton.off('click');
		}

		$(window)
			.off('resize', this._onResize)
			.off('scroll', this._onScroll);
	}

	_buttonExpand() {
		// on resize is-open class has to be removed
		if (this._mobileMenuToggleButton) {
			var maxWidth = this._mobileMenuToggleButton.content.css('max-width');

			if (maxWidth === 'none') {
				this.mobileMenuIsExpanded = false;
			} else {
				this.mobileMenuIsExpanded = $(window).scrollTop() < SCROLL_OFFSET;
			}
		}
	}

	_toggleActiveMobileMenu() {
		this.$el.toggleClass(IS_MOBIE_CLASSNAME, this.isMobile);
		this._navWrapper.find('> ul > li > a').attr('tabindex', !this.isMobile ? 0 : -1).trigger('blur');
	}

	_toggleAria($el, active) {
		$el.find('a, button').attr('tabindex', active ? 0 : -1);
		$el.attr('aria-hidden', !active).attr('aria-expanded', active);
	}

	_openSubmenu($el) {
		this._toggleAria($el.next(), true);
		$el.addClass('active').parent().addClass('is-open');

		if (!this.isMobile) {
			this.$el.find('.is-open .subnavigation') // add some padding. The submenu is aligned at the menu item
				.css({
					paddingLeft: $el.offset().left,
					top: this.$el.outerHeight()
				});
		}
	}

	_closeSubmenu() {
		if (this.subMenuIsOpen) {
			this._toggleAria(this.$el.find('.is-open > ul'), false);
			this.$el.find('.is-open').removeClass('is-open');
			this.$el.find('.active').removeClass('active');
		}

		this.$el.find('.subnavigation').css({
			paddingLeft: '',
			top: ''
		});
	}

	_onResize() {
		this._closeSubmenu();
		this._buttonExpand();
		this._toggleActiveMobileMenu();
	}

	_onToggleMobileMenu() {
		this.mobileMenuIsExpanded = !this.mobileMenuIsExpanded;
	}

	_onCloseSubmenu(e) {
		e.preventDefault();
		$(e.target).trigger('blur');
		this._closeSubmenu();
	}

	_onToggleSubmenu(e) {
		const $el = $(e.target);
		e.preventDefault();

		if ($el.hasClass('active')) { // close the menu if target is already open
			this._closeSubmenu();
		} else { // if target menu is closed, check if any other menu is open
			this.subMenuIsOpen && this._closeSubmenu(); // close if it is open
			this._openSubmenu($el);
		}
	}

}

export default View;
