import $ from 'jquery';
import _ from 'underscore';
import BaseView from 'picnic/core/views/Base';
import Template from 'app/base/header/views/Button.html';


const template = _.template(Template);


class View extends BaseView {

	constructor(options) {
		super(options);

		if (!options.controls) {
			throw new Error('button must have a control value');
		}

		_.bindAll(
			this,
			'_onClick'
		);
	}

	get isHidden() {
		return this._hidden;
	}

	set isHidden(value) {
		this._hidden = value;
		this._content.attr({
			'tabindex': value ? '-1' : '0',
			'aria-hidden': value.toString()
		});
	}

	get content() {
		return this._content;
	}

	render() {
		this._hidden = true;
		this._content = $(template()).appendTo(this.$el.find('.inner'))
			.attr('aria-controls', this.options.controls)
			.on('click', this._onClick);

		return this;
	}

	destroy() {
		this._content.off('click', this._onClick);
		super.destroy();
	}

	_onClick(event) {
		event.preventDefault();
		this.trigger('click');
		const value = this.$el.hasClass('is-open');
		this.context.dispatch('navigation:' + (value ? 'open' : 'close'), {isOpen: value});
	}

}

export default View;
