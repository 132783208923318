import $ from 'jquery';
import TemplateView from 'picnic/core/views/Template';
import Template from 'app/base/footer/views/Button.html';


var
	SELECTOR_HEADER = '.header',
	CLASS_OPEN = 'is-open'
;

class View extends TemplateView {

	get isOpen() {
		return this.$el.hasClass(CLASS_OPEN);
	}

	set isOpen(value) {
		this.$el.toggleClass(CLASS_OPEN, value).attr('aria-expanded', value);
		this.$el.find('a').attr('tabindex', value ? 0 : -1);
		this.$el.find('.container').attr('aria-hidden', !value);
	}

	get template() {
		return Template;
	}

	render() {
		super.render();
		this.isOpen = false;

		// Move the button after the title
		this.$el.find('h2').after(this.$el.find('.buttons'));

		return this;
	}

	events() {
		return {
			'click button': '_onClick'
		};
	}

	_replaceText(target) {
		const
			button = $(target),
			text = button.text()
		;
		button.text(button.data('toggle-text')).data('toggle-text', text);
	}

	_animateScroll() {
		const offset =  $(SELECTOR_HEADER).height() || 120;
		$('body, html').animate({
			scrollTop: this.$el.offset().top - offset
		}, 500);
	}

	_onClick(event) {
		event.preventDefault();
		this.isOpen = !this.isOpen;
		this._replaceText(event.currentTarget);
		this._animateScroll();
	}

}

export default View;
