import $ from 'jquery';
import _ from 'underscore';
import BaseView from 'picnic/core/views/Base';


var
	OPEN_CLASS = 'is-open',
	ATTR_ARIA_HIDDEN = 'aria-hidden',
	ATTR_ARIA_EXPANDED = 'aria-expanded'
;


class View extends BaseView {

	get isOpen() {
		return this.$el.hasClass(OPEN_CLASS);
	}

	set isOpen(state) {
		this.$el.toggleClass(OPEN_CLASS, state);
		this.$el.find('.share-overlay')
			.attr(ATTR_ARIA_HIDDEN, (!state).toString())
			.attr(ATTR_ARIA_EXPANDED, state.toString())
		;
		this.$el.find('a').attr('tabindex', state ? 0 : -1);
	}

	render() {
		const template = this.$el.find('.share-overlay');

		_.bindAll(
			this,
			'_onClick',
			'_onClose'
		);

		this._button = this.$el.find('.share').on('click', this._onClick);
		this._content = $(template).on('click', this._onClose);
		this.$el.find('a').attr('tabindex', -1);

		return this;
	}

	destroy() {
		this._button.off('click');
		super.destroy();
	}

	_onClick(event) {
		event.preventDefault();
		this.isOpen = !this.isOpen;
	}

	_onClose() {
		this.isOpen = false;
	}
}

export default View;
